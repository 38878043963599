import React from "react";
import Section from "../../components/section";

import bgD6 from "../../images/background-deco-6.svg";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css/bundle";
// import required modules
import { Pagination } from "swiper";

import user1 from "../../images/user/user1.png";
import user2 from "../../images/user/user2.jpeg";
import user3 from "../../images/user/user3.jpeg";
import user4 from "../../images/user/user4.jpeg";
import user5 from "../../images/user/user5.jpeg";
import user6 from "../../images/user/user6.jpeg";

const data = [
  {
    icon: user1,
    username: "po po",
    date: "2020年12月8日",
    content: "剛開始使用 練習倉頡的一個不錯的程式 值得推介",
  },
  {
    icon: user2,
    username: "LU YOU",
    date: "2020年5月24日",
    content:
      "非常好用嘅APP，有詳細講解倉頡各種規則及特殊例子，亦有各種階段嘅練習，深化所學到知識嘅吸收，多得哩個app同埋工作人員嘅努力研發，不斷改進，以及我個人嘅努力，終於學識咗夢寐以求嘅倉頡，在此，我僅代表我自己爲你哋研發哩個造福廣大倉頡初學者，倉頡愛好者等嘅App所作出嘅努力及貢獻而作出衷心感謝，希望你哋再接再勵，不斷進步，加油 ",
  },
  {
    icon: user3,
    username: "螢火之森",
    date: "2020年5月5日",
    content:
      "很實用，我依靠這個app初步學會了速成，app中速成字典這一項尤其有用，雖然要完整版才能體驗完整功能，但不買也很好用，這算是我學會速成後打的第一條評論吧。",
  },
  {
    icon: user4,
    username: "小恩",
    date: "2020年7月9日",
    content:
      "可以練習打字，很好，還是免費的，不用再去外面花錢練習，在家也可以練習，很適合小孩假日練習，🥳🤯😜",
  },
  {
    icon: user5,
    username: "SHU WA",
    date: "2020年4月15日",
    content:
      "實用性强，容易明白，簡單易操作，簡直是倉頡初學者的救星。Thank you !!!\n⭐⭐⭐⭐⭐",
  },
  {
    icon: user6,
    username: "Ooguma",
    date: "2020年6月30日",
    content:
      "已購買，很好用 呢個apps學倉頡好快上手，我學咗N年都唔識，用完幾日就學識了，幾推薦用",
  },
];

const Testimonial = () => {
  return (
    <Section
      backgrounds={{ lg: [bgD6], sm: [bgD6] }}
      autoHeight
      id="testimonial"
    >
      <div className="flex flex-col w-full justify-center items-center mb-16">
        <p className="text-white text-6xl mb-4 text-center">用戶評價</p>
        <p className="text-white text-xl mb-4 text-center">
          用戶評價取自Google Play應用商店
        </p>
        <div className="flex flex-col lg:flex-row">
          <Swiper
            autoHeight
            loop={true}
            centeredSlides={true}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {data.map(({ icon, username, date, content }) => (
              // <Slide review={review} />
              <SwiperSlide key={username}>
                <div
                  className="flex flex-col lg:flex-row justify-center lg:items-stretch items-center bg-white rounded-md shadow-md p-4 mx-8 w-full lg:w-auto select-none cursor-grab max-w-2xl"
                  style={{ marginBottom: 40 }}
                >
                  <img src={icon} className="rounded-full h-12 w-12 lg:mr-4" />
                  <div className="flex flex-col">
                    <p className="text-semibold text-center lg:text-left">
                      {username}
                    </p>
                    <p className="text-gray-600 text-center lg:text-left">
                      {date}
                    </p>
                    <p className="text-center lg:text-left">{content}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Section>
  );
};

export default Testimonial;
